import React from 'react';
import Logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import card1 from "../../assets/images/cards/card-1.png";
import card2 from "../../assets/images/cards/card-2.png";
import card3 from "../../assets/images/cards/card-3.png";
import card4 from "../../assets/images/cards/card-4.png";
import card5 from "../../assets/images/cards/card-5.png";
import card6 from "../../assets/images/cards/card-6.png";
import card7 from "../../assets/images/cards/card-7.png";
import card8 from "../../assets/images/cards/card-8.png";
import card9 from "../../assets/images/cards/card-9.png";
import card10 from "../../assets/images/cards/card-10.png";
import GooglePlay from "../../assets/images/icons/google-play.png";
import AppsStore from "../../assets/images/icons/apps-store.png";
import trustimg from "../../assets/images/trust.png";
import { Nav, NavItem, NavLink } from 'reactstrap';




// import card5 from "../../assets/images/cards/card-5.png";
// import card5 from "../../assets/images/cards/card-5.png";
// import card5 from "../../assets/images/cards/card-5.png";
// import card5 from "../../assets/images/cards/card-5.png";

// import UserHitenImg from "../../assets/images/ card6 } from "./cards/card-9.png";
// import UserHitenImg from "../../assets/images/ card7 } from "./cards/card-10.png";
// import UserHitenImg from "../../assets/images/ card8 } from "./cards/card-11.png";
// import UserHitenImg from "../../assets/images/ GooglePlay } from "./icons/google-play.png";
// import UserHitenImg from "../../assets/images/ AppsStore } from "./icons/apps-store.png";


function Footer() {
    return (
        <footer>
            <div className="footer-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="footer-widget2">
                                <h5 className="widget-title">Quick links</h5>
                                <ul className="quick-link-list d-flex">
                                    {/* {FooterQuickData.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <a em.link}>{item.title}</a>
                                            </li>
                                        );
                                    })} */}
                                    <li><a href="index.html">home</a></li>
                                    <li>
                                        {/* <a href="faq.html">FAQ</a> */}
                                        <Link to="/faq">FAQs</Link>
                                    </li>
                                    <li>
                                        <Link to="/about-us">About us</Link>
                                        {/* <a href="about-us.html">about us</a> */}
                                    </li>
                                    <li>
                                        <Link to="/how-to-play">how to play</Link>

                                        {/* <a href="how-to-play.html">how to play</a> */}
                                    </li>
                                    <li>
                                        <Link to="/tearms-of-service" style={{ textTransform: 'none' }}>Terms and Conditions</Link>

                                        {/* <a href="services.html">Terms of Service</a> */}
                                    </li>
                                    <li>
                                        <Link to="/privacy">Privacy policy</Link>

                                        {/* <a href="privacy-policy.html" target="_blank">Privacy</a> */}
                                    </li>
                                    {/* <li><a href="promotion.html">offers/promotion</a></li>  */}
                                    <li>
                                        <Link to="/legality">Legality</Link>

                                        {/* <a href="legality.html">Legality</a> */}
                                    </li>
                                    <li>

                                        <Link to="/disclaimer">Disclaimer</Link>

                                        {/* <a href="disclaimer.html">Disclaimer</a> */}
                                    </li>
                                    <li>
                                        <Link to="/contact-support">Contact Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/GST">GST Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="/TDS">TDS Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="/refunds-cancellations">Refund Policy</Link>
                                    </li>

                                    <li>
                                        <Link to="/withdrawal-policy">Withdrawal Policy</Link>
                                    </li>
                                    <li></li>

                                </ul>
                                <div className='assiatnce-footer'>
                                    <h4>For any assistance <a href='tel:1800 890 2466'>1800 890 2466</a></h4>
                                </div>
                                <Nav className="ml-auto align-items-start after-login download-btn footer-download-btn " navbar>
                                    <NavItem>
                                        <a href='https://apk-s3-gorummy.s3.ap-south-1.amazonaws.com/Rummy246.apk' rel="noreferrer" target="_blank">DOWNLOAD</a>
                                    </NavItem>
                                </Nav>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="footer-widget3">
                                <h5 className="widget-title">Security  & Certificates</h5>
                                <ul className="partner-listing d-flex">
                                    <li>
                                        <img src={card1} alt="card" />
                                    </li>
                                    <li>
                                        <img src={card2} alt="card" />
                                    </li>
                                    <li>
                                        <img src={card3} alt="card" />
                                    </li>
                                    <li>
                                        <img src={card4} alt="card" />
                                    </li>
                                    <li>
                                        <img src={card5} alt="card" />
                                    </li>
                                </ul>

                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className="footer-widget3">
                                <h5 className="widget-title">Social Links</h5>
                                <ul className="d-flex align-items-center star-list justify-content-center social-links">
                                    <li><a href='https://www.instagram.com/go_rummy?utm_source=qr&igsh=Y3I2dmV4YmVmdWQw' target='_blank'><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                                </ul>

                            </div>
                        </div>
                        {/* <div className="col-md-4">
                            <div className="footer-widget3">
                                <h5 className="widget-title">
                                    payment partners
                                </h5>
                                <ul className="partner-listing payment-listing d-flex">
                                    <li>
                                        <a target="_blank">
                                            <img src={card8} alt="card-11" />
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank">
                                            <img src={card7} alt="card-10" />
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank">
                                            <img src={card6} alt="card-9" />
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank">
                                            <img src={card9} alt="card-9" />
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank">
                                            <img src={card10} alt="card-9" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div >
            </div >
            <div className="copy-text text-center">
                <p>&copy; 2024 Eseries Sports Private Ltd. All Rights Reserved</p>
            </div>
        </footer >
    )
}

export default Footer