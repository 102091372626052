import React from 'react'
import Header from '../auth/Header';
import Footer from '../auth/Footer'

function RefundsCancellations() {
    return (
        <>
            <div>
                <Header />
                <section className="site-content about-page-section cmn-banner-bg cmn-pad">
                    <div className="container">
                        <div className="about-us-content">
                            <h3 className="text-center page-title refund-title">Refunds & Cancellations</h3>
                            <div className='about-details-sec m-pt-5'>
                                <h4>1 Games Termination during play</h4>
                                <h4>2 Games Pause during play</h4>
                                <h4>3 Third-party Gateway</h4>
                                <h4>4 Verification and Validation</h4>
                                <h4>5 Discontinuation of Promotional Offers</h4>
                                <h4>6 Requests for Refunds</h4>
                                <p></p>
                                <p>Given below are Eseries Sports Private Ltd.'s terms of refund in detail:
                                </p>
                                <h4>Games Termination during play
                                </h4>
                                <p>A game could get terminated due to inconsistency in internet connection, internet service outage, power outage, adware or malware attack, computer viruses, computer malfunction, any natural disasters or any other failure which is beyond our control - in all such cases of cancellation of the game, Eseries Sports Private Ltd. will not be held responsible. It is hereby understood that you have read these terms and continuing to use our services means you acknowledge and approve the same and you also acknowledge that Eseries Sports Private Ltd. will not be held responsible for any such cause of cancellation of the game and the losses incurred if any, due to such a cancellation of the game.</p>

                                <h4>Games Pause during play
                                </h4>
                                <p>When a Cash game is paused for a certain time interval due to unforeseen technical problems, a message to that effect would get flashed on the game table and lobby, displaying the interruption duration and instructing Players to not close the game table or lobby or to not log out from the Mobile App during the stated time interval. In case a Player chooses to either leave the table, exit the lobby or log out from the Mobile App during this interruption period then the Player would be considered as having forfeited the game and he would lose his buy-in amount against the lost game value, and it would not be refunded under any circumstances.
                                </p>
                                <p>At any point during the period of interruption mentioned in the notification, the game will continue exactly from where it got paused; in case if the game doesn't resume within the stated time interval, then it would be cancelled and the buy-in amounts of all the players playing that game would get refunded to Player wallet (account) within 24 hours.

                                </p>
                                <h4>Third-party Gateway
                                </h4>
                                <p>All financial transactions to make a deposit into your account on Eseries Sports Private Ltd.., using any financial instrument like a credit card, debit card, UPI or any other bank card/s are made through third-party payment gateways. The third-party payment gateway is responsible to accept or deny such a transaction/s and all such transactions made by you will be subject to the terms and conditions of the third party payment gateway and Eseries Sports Private Ltd.. will not be held responsible for any such denial by the third party.
                                </p>
                                <p>In case of reversal of amounts if any, transacted by you, Eseries Sports Private Ltd.. may reverse a specific amount of the transaction in full or as specified after the deduction of charges, if any, this refund of the amount will be made only to the financial instrument which was used by you to perform the transaction. You understand and acknowledge that all reversals will be made only to the financial instrument used.
                                </p>
                                <h4>Verification and Validation
                                </h4>
                                <p>Eseries Sports Private Ltd. validates the details provided by you at the time of registration at several stages and from time to time. Emails or SMS and phone calls are mediums used to communicate with you for validation purposes. While several attempts are made to contact you to validate the personal details provided by you, in cases where we are unable to establish contact with you and the validation process remains unsuccessful, Eseries Sports Private Ltd.reserves the right to suspend your account and remit the balance in your account (after deduction of service charges) to the instrument used by you to make the online transaction when making the deposit
                                </p>
                                <h4>Discontinuation of Promotional Offers
                                </h4>
                                <p>Eseries Sports Private Ltd. reserves the right to terminate or discontinue contests, games, offers, bonuses and tournaments without prior notice. In case of any such termination, discontinuation or cancellation, Eseries Sports Private Ltd.. will not be held liable, except for the entry fee for any such event as and when applicable.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default RefundsCancellations