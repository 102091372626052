import React from 'react'
import Header from '../auth/Header';
import Footer from '../auth/Footer'

function WithdrawalPolicy() {
    return (
        <>
            <div>
                <Header />
                <section className="site-content about-page-section cmn-banner-bg cmn-pad">
                    <div className="container">
                        <div className="about-us-content">
                            <h3 className="text-center page-title refund-title">Withdrawal Policy</h3>
                            <div className='about-details-sec'>
                                <h3 className='text-xl font-bold mt-4 mb-2'>Withdrawal Policy</h3>
                                <h4>Eligibility and Processing</h4>
                                <p>Ensure account verification and meet the minimum withdrawal amount. Withdrawals are processed within 7 business days, with times varying by payment method.</p>
                                <h4>Fees and Limits</h4>
                                <p>Be aware of any withdrawal fees and currency conversion charges. Adhere to specified daily, weekly, or monthly withdrawal limits.</p>
                                <h4>Security and Compliance</h4>
                                <p>Maintain secure account credentials and fulfill any bonus wagering requirements. Contact customer support for any issues or disputes. The policy complies with relevant regulations and may be updated as needed.</p>

                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default WithdrawalPolicy